import Sugar from "sugar"
import { s3AssetUrl, s3MediaKitUrl } from "utils"

export const LEAD_TYPES = {
  PAID: "Paid",
  ORGANIC: "Organic",
  MANUALLY_ADDED: "Manually Added",
}

const STEPPER_FEATURE = "listings"

const STEPPER_STEPS = {
  0: {
    stepNumber: 1,
    stepName: "basics",
  },
  1: {
    stepNumber: 2,
    stepName: "terms",
  },
  2: {
    stepNumber: 3,
    stepName: "photos",
  },
  3: {
    stepNumber: 4,
    stepName: "amenities",
  },
  4: {
    stepNumber: 5,
    stepName: "utilities",
  },
  5: {
    stepNumber: 6,
    stepName: "contact",
  },
  6: {
    stepNumber: 7,
    stepName: "title_and_description",
  },
  7: {
    stepNumber: 8,
    stepName: "showings",
  },
  8: {
    stepNumber: 9,
    stepName: "review_and_publish",
  },
}

const anyUnitAttributesChanged = (UNIT, attributes) =>
  Object.keys(attributes).some((a) => {
    if (typeof attributes[a] === "object") {
      // check if user has added any amenities_attributes
      return !Sugar.Object.isEmpty(attributes[a])
    }
    return attributes[a] !== UNIT[a]
  })

export const AVAIL = "avail"
export const RENTABLE = "rentable"
export const APARTMENTPICKS = "apartmentpicks"
export const APARTMENTLIST = "apartmentlist"
export const APARTMENTS = "apartments"
export const PADMAPPER = "padmapper"
export const REALTOR = "realtor"
export const RENTDOT = "Rent."
export const RENTALSDOTCOM = "Rentals.com"
export const APARTMENTSGUIDE = "ApartmentGuide.com"
export const REDFIN = "Redfin.com"
export const WALKSCORE = "walkscore"
export const ZUMPER = "zumper"
export const APARTMENTFINDER = "ApartmentFinder"
export const AHLIVING = "ApartmentHomeLiving"
export const FORRENT = "ForRent"
export const WESTSIDERENTALS = "WestsideRentals"
export const RENTBERRY = "Rentberry"
export const MOVOTO = "Movoto"
export const DWELLSY = "dwellsy"
export const RENTCAFE = "rentcafe"

const SyndicatedListingPartnerLogos = {
  [AVAIL]: s3AssetUrl("avail-logos/icon.svg"),
  [REALTOR]: s3AssetUrl("Logos/rdc.svg"),
  [ZUMPER]: s3AssetUrl("Logos/Zumper.svg"),
  [PADMAPPER]: s3AssetUrl("Logos/padmapper.svg"),
  [RENTABLE]: s3AssetUrl("Logos/rentable-chip.jpeg"),
  [DWELLSY]: s3AssetUrl("Logos/dwellsy-chip.png"),
  [APARTMENTPICKS]: s3AssetUrl("Logos/apartmentpicks-chip.png"),
}

const ListingPartnerWideLogos = {
  [AVAIL]: s3AssetUrl("avail-logos/wordmark%401x.png"),
  [ZUMPER]: s3AssetUrl("Logos/zumper_wide.svg"),
  [WALKSCORE]: s3MediaKitUrl("partner-logos/walkscore.svg"),
  [REALTOR]: s3MediaKitUrl("partner-logos/realtor.svg"),
  [PADMAPPER]: s3MediaKitUrl("partner-logos/padmapper.svg"),
  [APARTMENTLIST]: s3MediaKitUrl("partner-logos/apartment_list.svg"),
  [APARTMENTS]: s3MediaKitUrl("partner-logos/apartments_com.svg"),
  [RENTABLE]: s3AssetUrl("Logos/rentable.png"),
  [RENTDOT]: s3AssetUrl("Logos/rent-logo.png"),
  [RENTALSDOTCOM]: s3AssetUrl("Logos/rentals-dot-logo.svg"),
  [APARTMENTSGUIDE]: s3AssetUrl("Logos/ag-logo.svg"),
  [REDFIN]: s3AssetUrl("Logos/redfin-logo.svg"),
  [APARTMENTPICKS]: s3AssetUrl("Logos/ap-logo.svg"),
  [APARTMENTFINDER]: s3AssetUrl("Logos/af-logo.svg"),
  [AHLIVING]: s3AssetUrl("Logos/ahl-logo.svg"),
  [FORRENT]: s3AssetUrl("Logos/fr-logo.svg"),
  [WESTSIDERENTALS]: s3AssetUrl("Logos/wr-logo.svg"),
  [RENTBERRY]: s3AssetUrl("Logos/Rentberry_Logo_Icon.svg"),
  [MOVOTO]: s3AssetUrl("Logos/movoto-logo.svg"),
  [DWELLSY]: s3AssetUrl("Logos/dwellsy.png"),
  [RENTCAFE]: s3AssetUrl("marketing/rentcafe-logo.png"),
}

const listingSites = [
  { name: AVAIL, displayName: AVAIL, pollForStatus: true, order: 10 },
  {
    name: REALTOR,
    displayName: "realtor.com",
    pollForStatus: true,
    order: 20,
    promoted_feed_name: "realtor_premier",
  },
  {
    name: ZUMPER,
    displayName: ZUMPER,
    pollForStatus: true,
    order: 30,
    promoted_feed_name: "zumper_premier",
  },
  { name: RENTABLE, displayName: RENTABLE, pollForStatus: true, order: 40 },
  { name: PADMAPPER, displayName: PADMAPPER, pollForStatus: true, order: 50 },
  { name: APARTMENTPICKS, displayName: APARTMENTPICKS, pollForStatus: true, order: 60 },
  { name: DWELLSY, displayName: DWELLSY, pollForStatus: true, order: 70 },
  { name: APARTMENTLIST, displayName: APARTMENTLIST, pollForStatus: false, order: 80 },
  { name: WALKSCORE, displayName: WALKSCORE, pollForStatus: false, order: 90 },
  { name: APARTMENTS, displayName: APARTMENTS, pollForStatus: false, order: 100 },
  {
    name: RENTDOT,
    displayName: "Rent.",
    pollForStatus: false,
    order: 110,
    promoted_feed_name: "rentdot_premier",
  },
  { name: RENTALSDOTCOM, displayName: RENTALSDOTCOM, pollForStatus: false, order: 120 },
  { name: APARTMENTSGUIDE, displayName: APARTMENTSGUIDE, pollForStatus: false, order: 130 },
  { name: REDFIN, displayName: REDFIN, pollForStatus: false, order: 140 },
  { name: APARTMENTFINDER, displayName: APARTMENTFINDER, pollForStatus: false, order: 150 },
  { name: AHLIVING, displayName: AHLIVING, pollForStatus: false, order: 160 },
  { name: FORRENT, displayName: FORRENT, pollForStatus: false, order: 170 },
  { name: WESTSIDERENTALS, displayName: WESTSIDERENTALS, pollForStatus: false, order: 180 },
  { name: RENTBERRY, displayName: RENTBERRY, pollForStatus: false, order: 190 },
  { name: MOVOTO, displayName: MOVOTO, pollForStatus: false, order: 200 },
  { name: RENTCAFE, displayName: RENTCAFE, pollForStatus: false, order: 210 },
]

const compare = (a, b) => a.order - b.order

const nonStatusListingSites = listingSites
  .filter((listingSite) => !listingSite.pollForStatus)
  .sort(compare)

const realTimeStatusListingSites = listingSites
  .filter((listingSite) => !!listingSite.pollForStatus)
  .sort(compare)

export {
  anyUnitAttributesChanged,
  ListingPartnerWideLogos,
  STEPPER_FEATURE,
  STEPPER_STEPS,
  SyndicatedListingPartnerLogos,
  nonStatusListingSites,
  realTimeStatusListingSites,
}
