import React from "react"
import ReactDOM from "react-dom"
import { ElementsProvider } from "@rent_avail/core"
import { ToastProvider } from "@rent_avail/toast"
import ErrorBoundary from "components/shared/error"
import ApolloErrorToast from "components/shared/error/ApolloErrorToast"
import ApolloContainer from "components/ApolloContainer"

import { AutocompleteProvider } from "@rent_avail/autocomplete"
import { googleMapsApiKey } from "imported-vars"

export function renderAdminActionPack(node, Component, props) {
  ReactDOM.render(
    <ElementsProvider>
      <ToastProvider>
        <ErrorBoundary>
          <ApolloErrorToast />
          <ApolloContainer>
            <Component {...props} />
          </ApolloContainer>
        </ErrorBoundary>
      </ToastProvider>
    </ElementsProvider>,
    node,
  )
}

export function renderAdminContainerPack(node, Component, props) {
  ReactDOM.render(
    <ElementsProvider>
      <ToastProvider>
        <AutocompleteProvider apiKey={googleMapsApiKey}>
          <ErrorBoundary>
            <ApolloErrorToast />
            <ApolloContainer>
              <Component {...props} />
            </ApolloContainer>
          </ErrorBoundary>
        </AutocompleteProvider>
      </ToastProvider>
    </ElementsProvider>,
    node,
  )
}
